<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <v-container v-if="dataEndRequest">

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 black--text">
                <h1 class="text-center today-regular text-title">{{ dataEndRequest.title }}</h1>
              </div>
            </v-col>
            <v-col cols="11" md="6" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h3 class="primary--text text-center mb-0 today-light line-height-1">{{ dataEndRequest.message }}</h3>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="8" >

              <v-img v-if="dataEndRequest.card_image != ''"
                      max-height="200"
                     class="my-4"
                     contain
                     :src="dataEndRequest.card_image"
              ></v-img>

              <v-img v-else
                      max-height="200"
                     class="my-4"
                     contain
                     src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/dev/visa_gold-480x326.png"
              ></v-img>

              <v-card color="grey lighten-5 mt-10" elevation="0">

                <v-row justify="center" class="px-4">
                  <v-col cols="2" md="2">
                    <v-img max-height="30"
                           class="my-4"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/correo-electronico.png"
                    ></v-img>
                  </v-col>
                  <v-col justify-center cols="10" md="10" class="my-auto">

                    <div class="black--text mb-0 today-regular" style="font-size: 20px; line-height: 1;" v-html="dataEndRequest.email_text"></div>
<!--                    <p class="black&#45;&#45;text mb-0 today-regular" style="font-size: 20px; line-height: 1;">{{ dataEndRequest.email_text }}</p>-->
                  </v-col>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

          <v-layout justify-center class="mt-8">
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="goHome"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                {{ dataEndRequest.button_text }}
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      {{ messageSnackbar }}
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  name: 'FinishRequest',
  components: {LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      dialog: false,
      loading: false,
      settings: null,
      request: null,
      rules: {
        min: value => parseInt(value.replaceAll(',', '').replace('$', '')) > this.minAmount || 'Este valor debe ser mayor que el pago mínimo' + ' - ' + parseInt(value.replaceAll(',', '').replace('$', '')),
        max: value => parseInt(value.replaceAll(',', '').replace('$', '')) <= this.valueFinance || 'Este valor no debe ser mayor que el valor de la poliza',
      },
      timeout: 4000,
      dataEndRequest: null
    }
  },
  mounted() {
  },
  methods: {
    goHome() {
      locals.clearLocal();
      this.$router.push('/');
    },
    endRequest() {
      api.post(constants.endPoints.endRequest, {
        user_request_id: this.request.id,
      }, true)
        .then(response => {
          console.log('endRequest', response);
          this.dataEndRequest = response.data.data;
        }).catch(function (error) {
          //
          console.log('errors: ', error);

          try {
            let status = error.response.status;
            if(status == 401){
              locals.clearLocal();
              this.$router.push('/');
            }
            let errors = error.response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              console.log(key + ' : ' + value);
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.loading = false;
          } catch (e) {
            console.log(e);
            this.loading = false;
            //locals.clearLocal();
            //this.$router.push('/');
          }

        }.bind(this))

    },
  },
  created: function() {

    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');

    this.endRequest();

  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 13px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>